<template>
  <el-dialog
    v-model="dialogVisible"
    :title="`${cusProps.action === 'create' ? '新增' : '編輯'}客顯畫面`"
    width="880px"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="media-form"
      :rules="rules"
    >
      <el-form-item class="form-item" prop="title" style="grid-area: 1 / 1 / 2 / 3">
        <template #label>
          <span class="label">標題</span>
        </template>
        <el-input v-model="ruleForm.title" placeholder="請輸入活動標題" />
      </el-form-item>
      <el-form-item class="form-item" prop="available_shop_type">
        <template #label>
          <span class="label">門市</span>
          <el-radio-group v-model="ruleForm.available_shop_type" style="margin-left: 15px; transform: translateY(1px)">
            <el-radio v-for="item in available_shop_type_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-select
          v-if="ruleForm.available_shop_type === 2"
          v-model="ruleForm.available_shop_value_array"
          multiple
          placeholder="請選擇區域"
          style="width: 100%"
        >
          <el-option v-for="item in area_options" :key="item.label" :label="item.label" :value="item.value" />
        </el-select>
        <upload-file
          v-if="ruleForm.available_shop_type === 3"
          v-model:file_url="ruleForm.available_shop_file_url"
          v-model:file_name="ruleForm.available_shop_file_name"
        ></upload-file>
      </el-form-item>
      <el-form-item class="form-item" prop="time_period_type">
        <template #label>
          <span class="label">日期</span>
          <el-radio-group
            v-model="ruleForm.time_period_type"
            style="margin-left: 15px; transform: translateY(1px)"
            @change="clearItemValidate(ruleFormRef, 'time_period_type')"
          >
            <el-radio v-for="item in time_period_type_options" :key="item.label" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </template>
        <el-date-picker
          v-if="ruleForm.time_period_type === 2"
          v-model="datetimeRange"
          type="datetimerange"
          range-separator="－"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          format="YYYY/MM/DD HH:mm"
          value-format="YYYY/MM/DD HH:mm"
          :default-time="defaultTime"
        />
      </el-form-item>
      <el-form-item class="form-item" prop="media_url" style="grid-area: 3 / 1 / 4 / 3">
        <template #label>
          <span class="label">上傳檔案</span>
        </template>
        <template #error="{ error }">
          <span :class="['error', ruleForm.media_type === 2 ? 'error-right' : '']">{{ error }}</span>
        </template>
        <div class="upload-wrap">
          <div class="picture">
            <div class="label">
              <el-radio
                v-model="ruleForm.media_type"
                :label="1"
                :disabled="loadingVideo"
                @change="handleChangeType(1, 2)"
              >
                圖片
              </el-radio>
              <span class="tips fix-tips">尺寸：1280*1024 px，上限：1MB</span>
            </div>
            <upload-image
              v-if="ruleForm.media_type === 1"
              v-model:file_url="ruleForm.media_url"
              v-model:file_name="ruleForm.media_name"
            ></upload-image>
          </div>
          <div class="video">
            <div class="label">
              <el-radio v-model="ruleForm.media_type" :label="2" @change="handleChangeType(2, 1)">影片</el-radio>
              <span class="tips fix-tips">上限：100MB</span>
            </div>
            <upload-video
              v-if="ruleForm.media_type === 2"
              v-model:file_url="ruleForm.media_url"
              v-model:file_name="ruleForm.media_name"
              v-model:loadingVideo="loadingVideo"
            ></upload-video>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="handleClose(close)">取消</el-button>
        <el-button type="primary" @click="handleSubmit(ruleFormRef)" style="margin-left: 25px" :disabled="loadingVideo">
          {{ cusProps.action === 'create' ? '新增' : '儲存' }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref, watch, reactive, defineAsyncComponent, getCurrentInstance } from 'vue';
import { formatDateTime } from '@/utils/datetime';
import { useActions } from '@/utils/mapStore';
import { time_period_type_options, available_shop_type_options, area_options } from '@/utils/define';
import { useRouter } from 'vue-router';
export default {
  name: 'media-dialog',
  components: {
    UploadFile: defineAsyncComponent(() => import('@/components/common/UploadFile.vue')),
    UploadImage: defineAsyncComponent(() => import('@/components/common/UploadImage.vue')),
    UploadVideo: defineAsyncComponent(() => import('@/components/common/UploadVideo.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const submit = inject('submit');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);
    const datetimeRange = ref(null);
    const loadingVideo = ref(false);
    //設定初始值
    if (dialogVisible) {
      if (cusProps.init) {
        ruleForm.value = JSON.parse(JSON.stringify(cusProps.init));
        //日期格式
        if (ruleForm.value.time_period_type === 2) {
          datetimeRange.value = [];
          ruleForm.value.available_start_time = formatDateTime('YYYY/MM/DD HH:mm', ruleForm.value.available_start_time);
          ruleForm.value.available_end_time = formatDateTime('YYYY/MM/DD HH:mm', ruleForm.value.available_end_time);
          datetimeRange.value.push(ruleForm.value.available_start_time);
          datetimeRange.value.push(ruleForm.value.available_end_time);
        }
      } else
        ruleForm.value = {
          id: null,
          title: '',
          time_period_type: 1,
          available_start_time: null,
          available_end_time: null,
          available_shop_type: 1,
          available_shop_value_array: [],
          available_shop_file_url: null,
          available_shop_file_name: null,
          media_type: 1,
          media_url: null,
          media_name: null,
        };
    }

    //radio切換-清除錯誤提示
    const clearItemValidate = (form, val) => {
      form.clearValidate([val]);
    };
    watch(
      () => [
        ruleForm.value.available_shop_type,
        ruleForm.value.available_shop_value_array,
        ruleForm.value.available_shop_file_url,
      ],
      () => {
        clearItemValidate(ruleFormRef.value, 'available_shop_type');
      }
    );
    watch(
      () => [ruleForm.value.media_type, ruleForm.value.media_url],
      () => {
        clearItemValidate(ruleFormRef.value, 'media_url');
      }
    );
    //日期相關
    watch(
      () => datetimeRange.value,
      () => {
        if (!datetimeRange.value) {
          ruleForm.value.available_start_time = null;
          ruleForm.value.available_end_time = null;
        } else if (datetimeRange.value.length > 0) {
          ruleForm.value.available_start_time = datetimeRange.value[0];
          ruleForm.value.available_end_time = datetimeRange.value[1];
        }
      }
    );
    const defaultTime = [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 0)];

    //驗證
    const validateTime = (rule, value, callback) => {
      if (ruleForm.value.time_period_type === 1) callback();
      else {
        if (!datetimeRange.value) return callback(new Error('請選擇日期'));
        else callback();
      }
    };
    const validateShop = (rule, value, callback) => {
      switch (value) {
        case 1:
          callback();
          break;
        case 2:
          if (ruleForm.value.available_shop_value_array.length === 0) return callback(new Error('請選擇區域'));
          else callback();
          break;
        case 3:
          if (!ruleForm.value.available_shop_file_name || !ruleForm.value.available_shop_file_url)
            return callback(new Error('請選擇檔案'));
          else callback();
          break;
      }
    };
    const rules = reactive({
      title: [{ required: true, message: '請輸入標題', trigger: 'blur' }],
      time_period_type: [{ validator: validateTime, trigger: 'blur' }],
      available_shop_type: [{ validator: validateShop, trigger: 'blur' }],
      media_url: [{ required: true, message: '請選擇檔案', trigger: 'blur' }],
    });

    //切換檔案類型
    const handleChangeType = (value, oldValue) => {
      ruleForm.value.media_type = oldValue;
      if (ruleForm.value.media_url) {
        proxy
          .$confirm(`切換類型將會清空原資料，確定要切換嗎？`, '確認', {
            confirmButtonText: '確認',
            cancelButtonText: '取消',
            type: 'warning',
            confirmButtonClass: 'el-button--danger',
          })
          .then(() => {
            ruleForm.value.media_type = value;
            ruleForm.value.media_url = null;
            ruleForm.value.media_name = null;
          });
      } else {
        ruleForm.value.media_type = value;
      }
    };
    //關閉視窗
    const router = useRouter();
    const handleClose = (done) => {
      if (loadingVideo.value) {
        proxy
          .$confirm(`檔案正在上傳中，關閉此視窗將會終止上傳，確定要關閉嗎？`, '確認', {
            confirmButtonText: '確認',
            cancelButtonText: '取消',
            type: 'warning',
            confirmButtonClass: 'el-button--danger',
          })
          .then(() => {
            done();
            router.go();
          });
      } else {
        done();
      }
    };
    //送出表單
    const doSaveMedia = useActions('media', ['doSaveMedia']);
    const handleSubmit = async (form) => {
      try {
        // console.log(ruleForm.value);
        if (!form) return;
        await form.validate();
        await doSaveMedia(ruleForm.value);
        if (cusProps.action === 'create' || cusProps.action === 'edit') {
          proxy.$message({
            type: 'success',
            message: `${cusProps.action === 'create' ? '新增' : '修改'}成功`,
          });
        }
        submit(cusProps.action);
        close();
      } catch (e) {
        e;
      }
    };
    return {
      dialogVisible,
      close,
      cusProps,
      ruleFormRef,
      ruleForm,
      datetimeRange,
      loadingVideo,
      time_period_type_options,
      available_shop_type_options,
      area_options,
      defaultTime,
      rules,
      clearItemValidate,
      handleChangeType,
      handleClose,
      handleSubmit,
    };
  },
};
</script>
<style lang="scss" scoped>
.media-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 120px 420px;
  grid-column-gap: 25px;
}
.form-item {
  @include basic-form-item;
  .error {
    color: $color-danger;
    font-size: 12px;
    position: absolute;
    bottom: -30px;
  }
  .error-right {
    left: calc(50% + 12.5px);
  }
}
.dialog-footer {
  @include full-button;
}
.upload-wrap {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  .picture .video {
    width: 100%;
    height: 350px;
  }
  .fix-tips {
    display: inline-block;
    height: 32px;
    transform: translate(-30px, -2px);
  }
}
</style>
